<template>
  <v-app>
    <v-navigation-drawer
      app
      class="blue lighten-2 elevation-10"
      v-model="navigationVisible"
      >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title"> LevelUp </v-list-item-title>
          <v-list-item-subtitle> Dashboard </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list nav>
        <v-list-item
          v-for="item in menu"
          :key="item.id"
          class="pl-5 py-5"
          :to="item.to"
          :disabled="item.bindVar ? activeGame === null : false"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app elevation="5">
      <v-app-bar-nav-icon
        v-show="isMobile"
        @click.stop="hamburgerClicked($event)"
      />
      <v-toolbar-title>
        <strong>{{ menutitle }}</strong>
      </v-toolbar-title>
      <v-spacer />
      <v-btn rounded elevation="5" class="px-10 mr-2 error" @click="signOut()">
        Logga ut
      </v-btn>
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main>
      <!-- Provides the application the proper gutter -->
      <v-container class="fill-height">
        <router-view :key="$route.path"></router-view>
      </v-container>
    </v-main>

    <v-footer app>
      <!-- -->
    </v-footer>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Dashboard',
  data () {
    return {
      menu: [
        {
          title: 'Övervaka Spel',
          icon: 'mdi-play-circle',
          to: '/dashboard/supervise',
          bindVar: true
        },
        {
          title: 'Spelbibliotek',
          icon: 'mdi-gamepad-variant',
          to: '/dashboard/library'
        },
        {
          title: 'Skapa nytt',
          icon: 'mdi-folder-plus',
          to: '/dashboard/create'
        }
      ],
      menutitle: 'Spelbibliotek',
      navigationVisible: true
    }
  },
  computed: {
    isMobile () {
      return this.$vuetify.breakpoint.mobile
    },
    ...mapGetters('admin', [
      'activeGame'
    ])
  },
  methods: {
    signOut: function () {
      this.logout()
    },
    hamburgerClicked (val) {
      this.navigationVisible = true
    },
    ...mapActions('admin', [
      'logout',
      'loadAllGames',
      'stopLoadingAllGames'
    ])
  },
  /**
  watch: {
    activeGame (newVal, oldVal) {
      console.log('ActiveGame changed: ' + newVal + ' - old val: ' + oldVal)
    }
  }, */
  created () {
    // console.log('Created the dashboard')
    this.loadAllGames()
    this.disableSupervise = this.activeGame === null
  },
  destroyed () {
    // console.log('Destroyed the dashboard')
    this.stopLoadingAllGames()
  }
}
</script>
